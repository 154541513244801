html,
body{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.container{
  margin: 80px auto 0 auto;
  max-width: 1200px;
}

.card{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cardmedia{
  padding-top: 56%;
  height: 100px;
}

.cardContent{
  flex-grow: 1;
}

.adopted{
  background-color: #db0000;
}

.optionTitle,
.adoptedTitle{
  color: white;
  margin: 2px;
  text-align: center;
}

.option{
  background-color: #5c5c5c;
}

.iconAndText{
  display: flex;
  justify-items: center;
  align-items: center;
}

.rootGallery{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
  align-content: flex-start;
}

.gridGallery{
  width: 550px;
}

.dogDetailOverview{
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.info{
  width: 60%;
  margin-left: 20%;
  margin-right: auto;
}

.welcome{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  margin-left: 20%;
  margin-right: auto;
}

.hamburgerHide{
  display: none;
}

@media ( max-width: 800px) {
  .info {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .welcome{
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .gridGallery{
    width: 100%;
  }
  .galleryGridListTile{
    height: 180px !important;
  }
}